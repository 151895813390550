<template>
  <div class="animated fadeIn">
    <div>
      <h4 class="mb-3">
        {{ form.driver.surname }} {{ form.driver.name }} <span v-if="form.driver.patronymic">{{ form.driver.patronymic }}</span>
        <span
          v-if="form.driver.isCancel"
          class="text-danger"
        > (пометка на удаление)</span>
        <span v-else>
          <span
            v-if="form.driver.status === 1"
            class="text-success"
          > (допущен) </span>
          <span
            v-if="form.driver.status === 2"
            class="text-primary"
          > (на верификации) </span>
          <span
            v-if="form.driver.status === 4"
            class="text-primary"
          > (проверка логистом) </span>
          <span
            v-if="form.driver.status === 5"
            class="text-danger"
          > (отклонен) </span>
          <div v-if="(form.driver.status === 0 || form.driver.status === 3) && form.driver.blockedSecurity &&
                form.driver.validationStatus === 'Проверка выполнена' && 
                form.driver.decisionOnDriver === 'Нет решения'"
            >
                <span
                class="text-danger small"
              >(Проверка завершена. Водитель будет допущен к рейсу при условии предоставления следующих документов <br>
              (инст-ция водителя, инф. лист, лист инструктажа, фото водителя). Заполняются водителем на складе загрузки.)</span>
            </div>

            <div v-else>
                <span
                v-if="form.driver.status === 3"
                class="text-danger"
              >(не допущен) &ensp;</span>
              <span
              v-if="form.driver.status === 0"
              class="text-secondary"
              > (заблокирован СБ) &ensp;</span>
              <span
                v-if="form.driver.blockedSecurity"
                class="badge badge-danger"
              >Блокировка СБ &ensp;</span>
              <span
                v-if="form.driver.validationStatus === 'Проверка выполнена' &&  form.driver.decisionOnDriver === 'Нет решения'"
                class="text-danger font-weight-bold"
              >
                (Не хватает документов для принятия решения) &ensp;
              </span>
            </div>
        </span>
      </h4>
      <p
        v-if="form.driver.customerComment && form.driver.status === 5"
        class="text-danger"
      >
        Причина отклонения: "{{ form.driver.customerComment }}"
      </p>
      <p
        v-else-if="form.driver.customerComment && form.driver.status === 0"
        class="text-danger"
      >
        Причина возврата на редактирование: "{{ form.driver.customerComment }}"
      </p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Вид перевозки"
              >
                <b-form-radio-group v-model="accessType">
                  <b-form-radio
                    v-if="accessType=== 'auto'"
                    v-model="accessType"
                    name="accessType"
                    value="auto"
                    disabled
                  >
                    Автоперевозка
                  </b-form-radio>
                  <b-form-radio
                    v-if="accessType=== 'container'"
                    v-model="accessType"
                    name="accessType"
                    value="container"
                    disabled
                  >
                    ЖД перевозка (только на ЖД рейсы на доставку контейнера до ЖД станции)
                  </b-form-radio>
                  <b-form-radio
                    v-if="accessType=== 'cargoFlow'"
                    v-model="accessType"
                    name="accessType"
                    value="cargoFlow"
                    disabled
                  >
                    Услуга грузопровод (только по услуги грузопровод)
                  </b-form-radio>
                  <b-form-input
                    v-model="accessType"
                    disabled
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.driver.driverAccessAuto">
            <b-col md="12">
              <b-form-group
                label="Тип проверки"
              >
                <b-form-radio-group v-model="form.checkType">
                  <b-form-radio
                    v-if="form.checkType=== 0"
                    name="checkType"
                    :value="0"
                    disabled
                  >
                    Обычная проверка (личное посещение водителя СБ ПЭК – обычный пакет документов для проверки)
                  </b-form-radio>
                  <b-form-radio
                    v-if="form.checkType=== 1"
                    name="checkType"
                    :value="1"
                    disabled
                  >
                    Экспресс проверка (без личного посещения водителя СБ ПЭК – расширенный пакет документов)
                  </b-form-radio>
                  <b-form-input
                    v-model.trim="form.checkType"
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.checkType===0">
            <b-col md="4">
              <b-form-group
                class="form-group-el-select required"
                label="Отделение ПЭК для посещения его водителем для окончательной проверки"
              >
                <el-select
                  id="checkLocation"
                  v-model="form.checkLocation"
                  class="form-control"
                  name="checkLocation"
                  placeholder="Выберите отделение"
                  disabled
                >
                  <el-option
                    v-for="item in driverCheckLocations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <h5 class="custom-fieldset__title">
            Паспортные данные
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-group
                id="passportInputGroup1"
                class="form-group-el-select"
                label="Паспорт"
                label-for="passport-type"
              >
                <el-select
                  id="passport-type"
                  v-model="form.driver.passportType"
                  disabled
                  class="form-control"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="passportSeriesInputGroup2"
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-series"
                      v-model="form.driver.passportSeries"
                      disabled
                      type="text"
                      name="passport-series"
                      autocomplete="passport-series"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    id="passportNumberInputGroup3"
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-number"
                      v-model="form.driver.passportNumber"
                      disabled
                      type="number"
                      name="passport-number"
                      autocomplete="passport-number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportIssueDateInputGroup4"
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <el-date-picker-input
                  id="passport-issue-date"
                  v-model="form.driver.passportIssueDate"
                  disabled
                  type="date"
                  name="passport-issue-date"
                  autocomplete="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                id="passportIssuedByInputGroup5"
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  id="passport-issued-by"
                  v-model="form.driver.passportIssuedBy"
                  disabled
                  type="text"
                  name="passport-issued-by"
                  autocomplete="passport-issued-by"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup6"
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model="form.driver.surname"
                  disabled
                  type="text"
                  name="surname"
                  autocomplete="surname"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup7"
                label="Имя"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.driver.name"
                  disabled
                  type="text"
                  name="name"
                  autocomplete="name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup8"
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  id="patronymic"
                  v-model="form.driver.patronymic"
                  disabled
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="birthDateInputGroup19"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birth-date"
                  v-model="form.driver.birthDate"
                  disabled
                  type="date"
                  name="birth-date"
                  autocomplete="birth-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="innInputGroup7"
                label="ИНН водителя"
                label-for="inn"
              >
                <b-form-input
                  id="inn"
                  v-model="form.driver.inn"
                  type="text"
                  name="inn"
                  aria-describedby="innInputGroup7"
                  autocomplete="inn"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="driverLicenseInputGroup9"
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  id="drivers-license"
                  v-model="form.driver.driverLicense"
                  disabled
                  type="text"
                  name="drivers-license"
                  aria-describedby="input1LiveFeedback9"
                  autocomplete="drivers-license"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1"
              md="4"
            >
              <b-form-group
                id="instruction-label"
                label="Инструкция (подписанная водителем собственноручно)"
                label-for="instruction"
              >
                <div
                  v-if="form.instruction && form.instruction.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.instruction.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.instruction.file)"
                  >{{ form.instruction.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="infoSheet-label"
                label="Инф. Лист (подписанный водителем собственноручно)"
                label-for="infoSheet"
              >
                <div
                  v-if="form.infoSheet && form.infoSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.infoSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.infoSheet.file)"
                  >{{ form.infoSheet.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                v-if="accessType==='cargoFlow' || form.checkType===1"
                id="criminalZonesSheet-label"
                label="Лист криминогенных зон (подписанный водителем собственноручно)"
                label-for="criminalZonesSheet"
              >
                <div
                  v-if="form.criminalZonesSheet && form.criminalZonesSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.criminalZonesSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.criminalZonesSheet.file)"
                  >{{ form.criminalZonesSheet.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="selfie-label"
                label="Фото водителя (селфи)"
                label-for="selfie"
              >
                <div
                  v-if="form.selfie && form.selfie.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.selfie.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.selfie.file)"
                  >{{ form.selfie.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='container'"
              md="4"
            >
              <b-form-group
                id="power-of-attorney-label"
                label="Довереность от перевозчика"
                label-for="power-of-attorney"
              >
                <div
                  v-if="form.powerOfAttorney && form.powerOfAttorney.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.powerOfAttorney.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.powerOfAttorney.file)"
                  >{{ form.powerOfAttorney.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="form.driver.driverPhones.length > 0"
          class="custom-fieldset"
        >
          <h5 class="custom-fieldset__title">
            Контактная информация
          </h5>
          <div
            v-for="(phone, index) in form.driver.driverPhones"
            :key="`phone-${index}`"
          >
            +{{ phone.countryCode.code }} ({{ phone.areaCode }})-{{ phone.localNumber }}
            <span
              v-if="phone.additionalCode"
            >({{ phone.additionalCode }})</span>
          </div>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по допускам
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-checkbox
                id="access-auto"
                v-model="form.driver.driverAccessAuto"
                name="access-auto"
              >
                Допуск Авто
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-container"
                v-model="form.driver.driverAccessContainer"
                name="access-container"
                :disabled="form.driver.driverAccessAuto"
              >
                Допуск Контейнер
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-cargo-flow"
                v-model="form.driver.driverAccessCargoFlow"
                name="access-cargo-flow"
                :disabled="form.driver.driverAccessAuto"
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-header>
                  Перевозчики
                </b-card-header>
                <b-card-body :class="{'loading' : loading}">
                  <v-client-table
                    v-if="contractorDrivers.items"
                    id="dataTable"
                    :data="contractorDrivers.items"
                    :columns="contractorDrivers.columns"
                    :options="contractorDrivers.options"
                    :theme="contractorDrivers.theme"
                  >
                    <span
                      slot="passportScreenFirst"
                      slot-scope="props"
                    >
                      <span v-if="props.row.passportScreenFirst">
                        <p v-if="props.row.passportScreenFirst.file">
                          <a
                            :href="'/files/' + props.row.passportScreenFirst.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(props.row.passportScreenFirst.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="passportScreenSecond"
                      slot-scope="props"
                    >
                      <span v-if="props.row.passportScreenSecond">
                        <p v-if="props.row.passportScreenSecond.file">
                          <a
                            :href="'/files/' + props.row.passportScreenSecond.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(props.row.passportScreenSecond.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="contractFile"
                      slot-scope="props"
                    >
                      <span v-if="props.row.contractFiles.length > 0">
                        <p
                          v-for="(file, index) in props.row.contractFiles"
                          :key="`file-${index}`"
                        >
                          <a
                            :href="'/files/' + file.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(file.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="driverLicenseScreen"
                      slot-scope="props"
                    >
                      <span v-if="props.row.driverLicenseScreens.length > 0">
                        <p
                          v-for="(file, index) in props.row.driverLicenseScreens"
                          :key="`file-${index}`"
                        >
                          <a
                            :href="'/files/' + file.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(file.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="driverPhones"
                      slot-scope="props"
                    >
                      <span
                        v-for="(phone, index) in props.row.driverPhones"
                        :key="`phone-${index}`"
                      >
                        +{{ phone.countryCode.code }} ({{ phone.areaCode }})-{{ phone.localNumber }}
                        <span
                          v-if="phone.additionalCode"
                        >({{ phone.additionalCode }})</span>
                      </span>
                    </span>
                    <span
                      slot="activeAttorney"
                      slot-scope="props"
                    >
                      <span
                        v-if="props.row.activeAttorney"
                        class="text-success"
                      >
                        <b>Есть</b>
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      >
                        <b>Нет</b>
                      </span><br>
                      <a
                        :href="`/customer/contractors/id${props.row.contractor.id}?drivers%5B0%5D=${driverId}&tab=4`"
                        target="_blank"
                      >Посмотреть все</a>
                    </span>
                  </v-client-table>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <!--div class="custom-fieldset">
          <h5 class="custom-fieldset__title">Данные по доверенностям</h5>
          <div class="proxy-block" v-if="form.attorneys !== []" v-for="(attorney, index) in form.attorneys" :key="index">
            <h6>Доверенность №{{form.attorneys[index].number}}</h6>
            <b-row>
              <b-col md="6">
                <b-form-group :id="'attorneyNumberInputGroup' + index" label="Номер доверенности" :label-for="'attorney-number-' + index">
                  <b-form-input
                    :id="'attorney-number-' + index"
                    type="text"
                    :name="'attorney-number-' + index"
                    v-model.trim="form.attorneys[index].number"
                    :aria-describedby="'attorneyNumberLiveFeedback' + index"
                    autocomplete="attorney-number"
                    required />
                  <b-form-invalid-feedback :id="'attorneyNumberLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :id="'attorneyDateInputGroup' + index" label="Дата доверенности" :label-for="'attorney-date-' + index">
                  <b-form-input
                    :id="'attorney-date-' + index"
                    type="date"
                    :name="'attorney-date-' + index"
                    v-model.trim="form.attorneys[index].date"
                    :aria-describedby="'attorneyDateLiveFeedback' + index"
                    autocomplete="attorney-date"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyDateStartInputGroup' + index" label="Период действия с" :label-for="'attorney-date-start-' + index">
                  <b-form-input
                    :id="'attorney-date-start-' + index"
                    type="date"
                    :name="'attorney-date-start-' + index"
                    v-model.trim="form.attorneys[index].dateStart"
                    :aria-describedby="'attorneyDateStartLiveFeedback' + index"
                    autocomplete="attorney-date-start"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateStartLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyDateEndInputGroup' + index" label="Период действия по" :label-for="'attorney-date-end-' + index">
                  <b-form-input
                    :id="'attorney-date-end-' + index"
                    type="date"
                    :name="'attorney-date-end-' + index"
                    v-model.trim="form.attorneys[index].dateEnd"
                    :aria-describedby="'attorneyDateEndLiveFeedback' + index"
                    autocomplete="attorney-date-end"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateEndLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyInputGroup' + index" label="Доверенность" :label-for="'attorney' + index">
                  <b-form-file
                    :id="'attorney-' + index"
                    :name="'attorney-' + index"
                    v-model="form.attorneys[index].file"
                    v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                    accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                    placeholder="Файл не выбран"
                    browse-text="Выбрать"
                    drop-placeholder="Перетащите файл сюда"
                    @input="uploadAttorneyFile(index)"
                  ></b-form-file>
                  <div class="mt-2" v-if="form.attorneys[index].file.file">
                    Загруженный файл:
                    <a
                      @click.prevent="downloadFile(form.attorneys[index].file.file)"
                      :href="'/files/' + form.attorneys[index].file.file.guid"
                      target="_blank"
                    >{{form.attorneys[index].file.file.originalName}}</a>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <button class="btn btn-secondary" type="button" @click="addNewAttorneyFields">Добавить доверенность</button>
        </div-->
      </div>
      <div class="fixed-buttons">
        <b-button
          :disabled="loading"
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="form.driver.status === 4"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="customersContractorsDriverToVerification()"
        >
          Отправить на верификацию в СБ
        </b-button>
        <b-button
          v-if="form.driver.status === 4"
          type="button"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmReturnDriver()"
        >
          Вернуть на редактирование
        </b-button>
        <b-button
          v-if="form.driver.status === 4"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmRefusalDriver()"
        >
          Отклонить
        </b-button>
      </div>
    </div>
    <b-modal
      v-model="confirmReturnDriverModal.show"
      :title="confirmReturnDriverModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="returnDriver(confirmReturnDriverId)"
    >
      <p>{{ confirmReturnDriverModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmReturnDriverModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmReturnDriverModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
    <b-modal
      v-model="confirmRefusalDriverModal.show"
      :title="confirmRefusalDriverModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="refusalDriver(confirmRefusalDriverId)"
    >
      <p>{{ confirmRefusalDriverModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmRefusalDriverModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmRefusalDriverModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {required, minLength, email} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  customersDriverRead,
  customersContractorsDriverToCancel,
  customersContractorsDriverToNew,
  customersContractorsDriverToVerification,
  passportTypesList,
  downloadFile,
  viewFile, driverCheckLocations,
} from '../../services/api';
import moment from 'moment';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';

export default {
  name: 'CustomersDriversView',
  components: {multiplePhonesInput, ElDatePickerInput},
  mixins: [notifications],
  props: ['driverId'],
  data() {
    return {
      driverCheckLocations: [],
      accessType: null,
      passportTypes: [],
      form: {
        checkLocation: null,
        instruction: null,
        infoSheet: null,
        criminalZonesSheet: null,
        powerOfAttorney: null,
        selfie: null,
        checkType: null,
        driver: {
          id: null,
          status: null,
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          driverLicense: '',
          driverPhones: [],
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
          customerComment: null,
          inn: null,
        },
      },
      contractorDrivers: {
        columns: [
          'contractor.name',
          'passportScreenFirst',
          'passportScreenSecond',
          'contractFile',
          'driverLicenseScreen',
          'driverPhones',
          'activeAttorney',
        ],
        options: {
          headings: {
            'contractor.name': 'Перевозчик',
            'passportScreenFirst': 'Главная страница паспорта',
            'passportScreenSecond': 'Страница с пропиской',
            'contractFile': 'Документы договорных отношений водителя и работодателя',
            'driverLicenseScreen': 'Водительское удостоверение',
            'driverPhones': 'Контактная информация',
            'activeAttorney': 'Активная доверенность',
          },
          sortable: [],
          filterable: [],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Водитель не привязан ни к одному из перевозчиков',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      confirmReturnDriverModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      confirmRefusalDriverModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      loading: false,
    };
  },
  validations: {
    form: {
      driver: {
        passportType: {
          required,
        },
        passportSeries: {
          required,
        },
        passportNumber: {
          required,
        },
        passportIssueDate: {
          required,
        },
        passportIssuedBy: {
          required,
        },
        surname: {
          required,
        },
        name: {
          required,
        },
        patronymic: '',
        birthDate: {
          required,
        },
        driverLicense: {
          required,
        },
        driverAccessAuto: '',
        driverAccessContainer: '',
        driverAccessCargoFlow: '',
        inn: {
          required,
        },
      },
      passportScreenFirst: {
        required,
      },
      passportScreenSecond: {
        required,
      },
      driverLicenseScreen: {
        required,
      },
      contractFile: {
        required,
      },
    },
  },
  watch: {
    '$route'() {
      this.customersDriverRead(this.driverId);
    },
  },
  mounted() {
    this.passportTypesList();
    this.customersDriverRead(this.driverId);
    this.getLocations();
  },
  methods: {
    viewFile,
    downloadFile,
    addNewAttorneyFields() {
      this.form.attorneys.push({
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      });
    },
    async passportTypesList(params) {
      this.loading = true;
      const response = await passportTypesList(params);
      if (response && response.status === 200) {
        this.passportTypes = response.data.items.map((type) => {
          return {
            value: type.id,
            text: type.type,
          };
        });
      }
      this.loading = false;
    },
    async customersDriverRead(driverId) {
      this.loading = true;
      const response = await customersDriverRead(driverId);
      if (response && response.status === 200) {
        Object.keys(response.data).map((item) => {
          if (item === 'passportType') {
            this.form.driver[item] = response.data[item].id;
          } else if (
            item === 'guid' ||
            item === 'driverBlockedSecurity' ||
            item === 'createdOn' ||
            item === 'updatedOn'
          ) {
            // nothing
          } else {
            this.form.driver[item] = response.data[item];
          }
        });
        this.form.driverPhones = response.data.driverPhones;
        if (response.data.contractorDrivers && response.data.contractorDrivers.length > 0) {
          response.data.contractorDrivers.forEach((contractorDriver) => {
            if (contractorDriver.customerComment !== null) {
              this.form.driver.customerComment = contractorDriver.customerComment;
              this.confirmReturnDriverModal.comment = contractorDriver.customerComment;
              this.confirmRefusalDriverModal.comment = contractorDriver.customerComment;
              if (contractorDriver.instruction) {
                this.form.instruction = contractorDriver.instruction;
              }
              if (contractorDriver.infoSheet) {
                this.form.infoSheet = contractorDriver.infoSheet;
              }
              if (contractorDriver.criminalZonesSheet) {
                this.form.criminalZonesSheet = contractorDriver.criminalZonesSheet;
              }
              if (contractorDriver.powerOfAttorney) {
                this.form.powerOfAttorney = contractorDriver.powerOfAttorney;
              }
              if (contractorDriver.selfie) {
                this.form.selfie = contractorDriver.selfie;
              }
              if (contractorDriver.checkLocation) {
                this.form.checkLocation = contractorDriver.checkLocation.id;
              }
              this.form.checkType = contractorDriver.checkType;
              if (this.form.driver.driverAccessAuto) {
                this.accessType = 'auto';
              } else if (this.form.driver.driverAccessContainer) {
                this.accessType = 'container';
              } else if (this.form.driver.driverAccessCargoFlow) {
                this.accessType = 'cargoFlow';
              }
            }
            contractorDriver.attorneys.map((attorney) => {
              if (attorney.status === 2) {
                const updateTime = moment(attorney.updatedOn).format('x');
                const currentTime = moment().format('x');
                const limit = 604800000;
                if (currentTime - updateTime >= limit) {
                  attorney.hidden = true;
                }
              }
              if (!attorney.file) {
                attorney.file = {};
              }
              return attorney;
            });
          });
          this.contractorDrivers.items = response.data.contractorDrivers;
        }
      }
      this.loading = false;
    },
    async returnDriver() {
      this.loading = true;
      const response = await customersContractorsDriverToNew(this.contractorDrivers.items[0].id, this.confirmReturnDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель возвращен на редактирование');
        this.$router.go();
      }
      this.loading = false;
    },
    confirmReturnDriver() {
      this.confirmReturnDriverModal = {
        show: true,
        title: 'Возврат водителя на редактирование',
        desc: 'Для того, чтобы вернуть водителя на редактирование, необходимо указать причину возврата. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
    },
    async refusalDriver() {
      this.loading = true;
      const response = await customersContractorsDriverToCancel(this.contractorDrivers.items[0].id, this.confirmRefusalDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отклонен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmRefusalDriver() {
      this.confirmRefusalDriverModal = {
        show: true,
        title: 'Отклонение водителя',
        desc: 'Для того, чтобы отклонить водителя, необходимо указать причину отклонения. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
    },
    async customersContractorsDriverToVerification() {
      this.loading = true;
      const response = await customersContractorsDriverToVerification(this.contractorDrivers.items[0].id);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на верификацию в СБ');
        this.$router.go();
      }
      this.loading = false;
    },
    async getLocations() {
      const response = await driverCheckLocations({});
      if (response && response.status === 200) {
        this.driverCheckLocations = response.data;
      }
    },
  },
};
</script>

<style lang="scss">
</style>

